import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/public/images/logo/logo-2.png");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/src/app/(main)/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AOSInit"] */ "/app/src/components/AOS/AOSInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ScrollToTop/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/index.css");
