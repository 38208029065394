// app/providers.js
"use client";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React from "react";

export function CSPostHogProvider({ children }) {
  // Remove process.env.NODE_ENV === production for testing posthog
  React.useEffect(() => {
      if (typeof window !== "undefined" && !posthog.__loaded && process.env.NODE_ENV === "production") {
        posthog.init("phc_DTI42XrscpKwkdfAZVVlKLNm881W26B3lD3kBL1FRtC", {
          api_host: "https://us.i.posthog.com",
          person_profiles: "always", // or 'always' to create profiles for anonymous users as well
          capture_pageview: true,
          capture_pageleave: true
        });
      }

    }, []
  );
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
