import { Menu } from "@/types/menu";

const menuData: Menu[] = [
  {
    id: 1,
    title: "Home",
    path: "/",
    newTab: false
  },
  // {
  //   id: 4,
  //   title: "Solutions",
  //   newTab: false,
  //   submenu: [
  //     {
  //       id: 14,
  //       title: "Job Seekers",
  //       path: "/products/MockMate",
  //       newTab: false
  //     },
  //     {
  //       id: 14,
  //       title: "Enterprise Users",
  //       path: "/products/TalentBoost",
  //       newTab: false
  //     }
  //   ]
  // },
  {
    id: 4,
    title: "Products",
    newTab: false,
    submenu: [
      {
        id: 10,
        title: "MockMate (For Job Seekers)",
        path: "/products/MockMate",
        newTab: false
      }, {
        id: 11,
        title: "TalentBoost (For Enterprises)",
        path: "/products/TalentBoost",
        newTab: false
      }
    ]
  }
];
export default menuData;
