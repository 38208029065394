'use client';

import { ThemeProvider } from 'next-themes';
import React from 'react';
import { CSPostHogProvider } from '@/hooks/postHogProvider';

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <CSPostHogProvider>
      <ThemeProvider attribute="class" enableSystem={false} defaultTheme="light">
        {children}
      </ThemeProvider>
    </CSPostHogProvider>

  );
}
